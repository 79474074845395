import moment from "moment/moment";
moment.locale("es");
export const validateLicenceDate = (birthday, licence) => {
    var birthDate = moment(birthday, "DD/MM/YYYY");
    var licenceDate = moment(licence, "DD/MM/YYYY");
    const dif = moment.duration(licenceDate.diff(birthDate));
    if (dif.asYears() < 18) {
        return false;
    }
    return true;
};
