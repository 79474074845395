export const AUTH_LOADING = "AUTH_LOADING";
export const AUTH_ERROR = "AUTH_ERROR";
export const AUTH_RETURN_URL = "AUTH_RETURN_URL";
export const AUTH_UNAUTHORIZE = "AUTH_UNAUTHORIZE";
export const AUTH_OUT_SESSION = "AUTH_OUT_SESSION";
export const AUTH_SET_EXPIRATIONTIME = "AUTH_SET_EXPIRATIONTIME";

export const URLS_TO_RETURN = {
    RENTING_VO: "RentingVO",
    SIMULATION_VO: "RentingSimulationVO",
};

export const ROUTE_SET = "ROUTE_SET";
