import axios from "axios";
import { outSession } from "../actions/authAction";
import AuthenticationService from "../services/AuthenticationService";
import LocalStorageService from "../services/LocalStorageService";
import { store } from "../store/store";

const CancelToken = axios.CancelToken;
const sourceArray = [];

let server = "";
export function getServer(serviceType) {
    switch (serviceType) {
        case "imaweb":
            return "https://vw.imaweb.net/app/myrenting/my_renting.procces";
        case "core":
        default:
            return import.meta.env.VITE_APP_BASE_URL;
    }
}

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
    failedQueue.forEach((prom) => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve(token);
        }
    });

    failedQueue = [];
};

const localStorageService = new LocalStorageService();

axios.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;

        if (originalRequest._retry) {
            switch (error.response.status) {
                case 401:
                    store.dispatch(outSession());
                    break;
                case 403:
                    console.error("no autorizado");
                    break;
                default:
                    break;
            }
        }

        if (error.response.status === 401 && !originalRequest._retry) {
            if (isRefreshing) {
                return new Promise((resolve, reject) => {
                    failedQueue.push({ resolve, reject });
                })
                    .then((token) => {
                        originalRequest._retry = true;
                        originalRequest.headers["Authorization"] = "Bearer " + token;
                        return axios(originalRequest);
                    })
                    .catch((err) => {
                        return Promise.reject(err);
                    });
            }

            originalRequest._retry = true;
            isRefreshing = true;

            return new Promise((resolve, reject) => {
                const tokenJWT = localStorageService.getToken();
                new AuthenticationService()
                    .refreshtoken(tokenJWT)
                    .then((newToken) => {
                        if (newToken.body !== null) {
                            localStorageService.setToken(newToken.body);
                            axios.defaults.headers.common["Authorization"] = "Bearer " + newToken.body.AccessToken;
                            originalRequest.headers["Authorization"] = "Bearer " + newToken.body.AccessToken;
                            processQueue(null, newToken.body.AccessToken);
                            resolve(axios(originalRequest));
                        }
                        store.dispatch(outSession());
                        // throw "No token was given"
                    })
                    .catch((err) => {
                        processQueue(err, null);
                        reject(err);
                        store.dispatch(outSession());
                    })
                    .finally(() => (isRefreshing = false));
            });
        }
        return Promise.reject(error);
    }
);

const AppService = {
    get: function (url, conf, serviceType) {
        server = getServer(serviceType);
        var config = {};
        if (conf) config = conf;
        config.headers = { "Access-Control-Allow-Origin": "*" };

        var authorization = localStorageService.getToken();
        if (authorization) {
            config.headers.Authorization = `Bearer ${authorization}`;
        }
        return axios
            .get(server + url, config)
            .then((response) => {
                return response;
            })
            .catch(function (error) {
                var response = {};
                response.data = error;
                return response;
            });
    },
    getSourceForFutureCancelRequest: function (url) {
        let sourceElement = sourceArray.find((item) => item.url === url);
        if (sourceElement) {
            sourceElement.source.cancel("Operation canceled.");
            sourceElement.source = CancelToken.source();
        } else {
            sourceArray.push({
                url,
                source: CancelToken.source(),
            });
            sourceElement = sourceArray[sourceArray.length - 1];
        }
        return sourceElement;
    },
    deleteSourceFromArray: function (url) {
        let sourceElement = sourceArray.find((item) => item.url === url);
        if (sourceElement) {
            let index = sourceArray.indexOf(sourceElement);
            sourceArray.splice(index, 1);
        }
    },
    post: function (url, body, conf, serviceType) {
        let sourceElement = AppService.getSourceForFutureCancelRequest(url);

        server = getServer(serviceType);
        var data = {};
        var config = {};
        if (conf) config = conf;
        config.headers = { "Access-Control-Allow-Origin": "*" };
        config.cancelToken = sourceElement.source.token;

        var authorization = localStorageService.getToken();
        if (authorization) {
            config.headers.Authorization = `Bearer ${authorization}`;
        }

        data.Body = JSON.stringify(body);
        return axios
            .post(server + url, data, config)
            .then((response) => {
                AppService.deleteSourceFromArray(url);
                let parse = JSON.parse(response.data.body);
                response.data.body = parse.Result;
                if (parse.Error) response.data.error = parse.Error;
                if (parse.Errors) response.data.error = parse.Errors;

                return response.data;
            })
            .catch((error) => {
                AppService.deleteSourceFromArray(url);
                var response = {};
                var statusCode = error.response ? error.response.status : 409;
                var errorMsg = error.response ? error.response.statusText : "Canceled operation";
                response.statusCode = statusCode;
                response.body = {};
                response.body.error = [];
                response.body.error.push({ code: statusCode, value: errorMsg });
                return response;
            });
    },
    fsovurl: () => {
        return "fsoneviewservice";
    },
};

export default AppService;
