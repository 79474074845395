import OfferService from "../services/OfferService";
import QuotationService from "../services/QuotationService";
import { addNotificationError, addNotificationSuccess } from "./notificationAction";
import { adapterSimulationsByFam } from "../adapters/adapterSimulationsByFam";
import { types } from "../types/types";
import { removelAllAdditionalProductsInHost } from "./addictionalProductsAction";
import OfferMantenimientoLongDriveService from "../services/OfferMantenimientoLongDriveService";
import OfferSeguroAutoLongDriveService from "../services/OfferSeguroAutoLongDriveService";
import { adapterClientDateFormat } from "../adapters/adapterClientDateFormat";
import { setSelectedResumeOffers } from "./summaryAction";
import { saveProspectData } from "./globalAction";
import { CalculatorTypeEnum } from "./../types/enums";

export const getOffers = () => {
    return async (dispatch) => {
        let offerService = new OfferService();
        const { data, status, errors } = await offerService.getOffers();
        if (status === 200) {
            dispatch({
                type: types.offerSetSimulations,
                payload: adapterSimulationsByFam(data) ?? [],
            });
        } else {
            dispatch(addNotificationError(errors));
        }
    };
};

export const calculate = (obj) => {
    return async (dispatch, getState) => {
        dispatch(setIsCalculating(true));
        const quotationService = new QuotationService();
        const { data, status, errors } = await quotationService.getQuote(obj);
        const state = getState();

        if (status === 200) {
            var { addicionalProdsList } = state.addicionalProducts;
            data.addicionalProdsList = addicionalProdsList;
            dispatch(addSimulation(data));
            dispatch(getLegals(data, false));
        } else {
            if (state.global.calculatorType === 3) {
                dispatch(removelAllAdditionalProductsInHost());
            }

            dispatch(addNotificationError(errors));
        }
        dispatch(setIsCalculating(false));
    };
};

export const setIsCalculating = (payload) => ({
    type: types.offerSetIsCalculating,
    payload,
});

export const setIsLoading = (payload) => ({
    type: types.offerSetIsLoading,
    payload,
});
export const setIsSendingOffers = (payload) => ({
    type: types.offerSetIsSendingOffers,
    payload,
});

export const addSimulation = (simulation) => ({
    type: types.offerAddSimulation,
    payload: simulation,
});

export const setShowComparatorModal = (payload) => ({
    type: types.offerSetShowComparatorModal,
    payload,
});

export const removeOffers = ({ Offers }) => {
    return async (dispatch) => {
        dispatch(removeComparableOffer(Offers));
        dispatch({
            type: types.offerRemoveSimulation,
            payload: Offers,
        });

        dispatch(
            addNotificationSuccess(
                Offers.length === 1 ? "Se ha eliminado la simulación" : "Se han eliminado las simulaciones",
            ),
        );

        const offerService = new OfferService();
        offerService.removeOffers({ Offers });
    };
};

export const createQuoteOffer = (obj) => {
    return async (dispatch, getState) => {
        var quotationService = new QuotationService();
        var retval = {
            IsSended: false,
            NeedSend: true,
            ItsDone: false,
            OffersId: [],
        };
        if (obj === null) {
            retval.IsSended = true;
            retval.NeedSend = false;
            dispatch(setIsSimulatorOfferSended(retval));
            return;
        }
        const { data, status, errors } = await quotationService.createQuoteOffer(obj);
        retval.IsSended = true;
        if (data === undefined && status == undefined && errors == undefined) {
            dispatch(setIsSimulatorOfferSended(retval));
            return;
        }

        if (status !== 200) {
            dispatch(setIsSendingOffers(false));
            dispatch(addNotificationError(errors));
            dispatch(setIsSimulatorOfferSended(retval));
            return;
        }

        retval.ItsDone = true;
        data.forEach((item) => {
            retval.OffersId.push(item.Offer.CodigoOferta);
        });

        const { calculatorType } = getState().global;
        if (calculatorType === CalculatorTypeEnum.LEASING) {
            dispatch(saveProspectData(data[0]));
        }

        dispatch(setIsSimulatorOfferSended(retval));
        dispatch(setOffers(data));
        return;
    };
};

export const addComparableOffer = (payload) => ({
    type: types.offerAddComparableOffer,
    payload,
});

export const removeComparableOffer = (payload) => ({
    type: types.offerRemoveComparableOffer,
    payload,
});

export const setModalDetailSelected = (payload) => {
    return async (dispatch) => {
        dispatch(getLegals(payload));
    };
};

export const getLegals = (simulation, openModal = true) => {
    return async (dispatch, getState) => {
        if (!simulation?.AdditionalProducts && simulation?.ProdFam?.Key === "FINA") {
            const offerService = new OfferService();
            const { data, status, errors } = await offerService.getLegals(simulation.Code);
            if (status !== 200) {
                dispatch(addNotificationError(errors));
                if (openModal) {
                    dispatch({
                        type: types.offerSetModalDetailSelected,
                        payload: simulation,
                    });
                }
                return;
            }

            // Modificar Offer en simulacion
            var { simulations } = getState().offer;

            var fam = simulations.filter((x) => x.ProdFam == simulation.Product.Key);

            if (fam[0]) {
                var sim = fam[0].Simulations?.filter((x) => x.Code === simulation.Code);
                if (sim[0]) {
                    sim[0].AdditionalProducts = data;
                    dispatch({
                        type: types.offerSetSimulations,
                        payload: simulations,
                    });
                    if (openModal) {
                        dispatch({
                            type: types.offerSetModalDetailSelected,
                            payload: sim[0],
                        });
                    }
                }
            }
        } else if (openModal) {
            dispatch({
                type: types.offerSetModalDetailSelected,
                payload: simulation,
            });
        }
    };
};

export const getLegalsSelectedResumeOffers = (simulations) => {
    return async (dispatch, getState) => {
        var Result = [];
        console.log(simulations);
        console.log(getState().global);
        await simulations?.filter(async (simulation) => {
            if (!simulation?.AdditionalProducts && simulation?.ProdFam?.Key === "FINA") {
                const offerService = new OfferService();
                const { data, status, errors } = await offerService.getLegals(simulation.Code);
                if (status !== 200) {
                    dispatch(addNotificationError(errors));
                    return;
                }
                simulation.AdditionalProducts = data;
                Result.push(simulation);
            } else {
                Result.push(simulation);
            }
            dispatch(setSelectedResumeOffers(Result));
        });
    };
};

export const setOffers = (payload) => ({
    type: types.offerSetOffers,
    payload,
});

export const saveMantenimientoStandAloneOffer = () => {
    return async (dispatch, getState) => {
        const { selectedMantenimentoStandAlone, selectedClientStandAlone } = getState().summary;
        var retval = {
            IsSended: false,
            NeedSend: true,
            ItsDone: false,
            OfferId: "",
            NumOfertaCliente: "",
        };
        if (selectedMantenimentoStandAlone === null) {
            retval.IsSended = true;
            retval.NeedSend = false;
            dispatch(setIsMantenimientoOfferSended(retval));
            return;
        }
        var request = {
            MantenimientoLDSelected: selectedMantenimentoStandAlone,
            ClientInfo: selectedClientStandAlone,
        };

        var offerMantenimientoLongDriveService = new OfferMantenimientoLongDriveService();
        const { data, status, errors } =
            await offerMantenimientoLongDriveService.saveMantenimientoStandAloneOffer(request);

        retval.IsSended = true;
        if (data === undefined && status == undefined && errors == undefined) {
            dispatch(setIsMantenimientoOfferSended(retval));
            return;
        }
        if (status === 200 && data?.ItsDone === true) {
            retval.ItsDone = data?.ItsDone;
            retval.OfferId = data?.OfferId;
            retval.NumOfertaCliente = data?.NumOfertaCliente;
            dispatch(setIsMantenimientoOfferSended(retval));
            return;
        }
        if (status !== 200) {
            dispatch(setIsSendingOffers(false));
            dispatch(addNotificationError(errors));
            dispatch(setIsMantenimientoOfferSended(retval));
            return;
        }
    };
};

export const saveSeguroAutomovilStandAloneOffer = () => {
    return async (dispatch, getState) => {
        const { selectedSeguroAutoStandAlone, selectedClientStandAlone } = getState().summary;
        var retval = {
            NeedSend: true,
            IsSended: false,
            ItsDone: false,
            OfferId: "",
            NumOfertaCliente: "",
        };
        if (selectedSeguroAutoStandAlone?.SeguroContratado === null) {
            retval.IsSended = true;
            retval.NeedSend = false;
            dispatch(setIsSeguroAutomovilOfferSended(retval));
            return;
        }

        var clientsSA = [
            adapterClientDateFormat(selectedSeguroAutoStandAlone.Tomador),
            adapterClientDateFormat(selectedSeguroAutoStandAlone.ConductorHabitual),
        ];

        if (selectedSeguroAutoStandAlone.CondutorOcasional?.NIF !== "") {
            clientsSA.push(adapterClientDateFormat(selectedSeguroAutoStandAlone.CondutorOcasional));
        }

        var request = {
            SegurosAutoSelected: [selectedSeguroAutoStandAlone.SeguroContratado],
            ClientsSA: clientsSA,
            ClientInfo: selectedClientStandAlone,
        };

        var offerSeguroAutoLongDriveService = new OfferSeguroAutoLongDriveService();
        const { data, status, errors } = await offerSeguroAutoLongDriveService.saveSeguroAutoStandAloneOffer(request);
        retval.IsSended = true;
        if (data === undefined && status == undefined && errors == undefined) {
            dispatch(setIsSeguroAutomovilOfferSended(retval));
            return;
        }
        if (status === 200 && data?.ItsDone === true) {
            retval.ItsDone = data?.ItsDone;
            retval.OfferId = data?.OfferId;
            retval.NumOfertaCliente = data?.NumOfertaCliente;
            dispatch(setIsSeguroAutomovilOfferSended(retval));
            return;
        }
        if (status !== 200) {
            retval.ItsDone = false;
            dispatch(setIsSendingOffers(false));
            dispatch(addNotificationError(errors));
            dispatch(setIsSeguroAutomovilOfferSended(retval));
            return;
        }
    };
};

export const saveOffersFSOVLongDriveRelation = () => {
    return async (dispatch, getState) => {
        const { isSimulatorOfferSended, isMantenimientoOfferSended, isSeguroAutomovilOfferSended } = getState().offer;

        if (
            (isSimulatorOfferSended.OffersId && isSimulatorOfferSended.OffersId.length > 0) ||
            isMantenimientoOfferSended.OfferId != "" ||
            isSeguroAutomovilOfferSended.OfferId != ""
        ) {
            var offerService = new OfferService();
            if (isSimulatorOfferSended.OffersId && isSimulatorOfferSended.OffersId.length > 0) {
                isSimulatorOfferSended.OffersId.forEach(async (offer) => {
                    offerService.createOffersFSOVLongDriveRelation({
                        FinanceOfferId: offer,
                        MantenimientoLDOfferId:
                            isMantenimientoOfferSended.OfferId != "" ? isMantenimientoOfferSended.OfferId : null,
                        SeguroAutoLDOfferId:
                            isSeguroAutomovilOfferSended.OfferId != "" ? isSeguroAutomovilOfferSended.OfferId : null,
                    });
                });
                return;
            } else {
                offerService.createOffersFSOVLongDriveRelation({
                    FinanceOfferId: undefined,
                    MantenimientoLDOfferId:
                        isMantenimientoOfferSended.OfferId != "" ? isMantenimientoOfferSended.OfferId : null,
                    SeguroAutoLDOfferId:
                        isSeguroAutomovilOfferSended.OfferId != "" ? isSeguroAutomovilOfferSended.OfferId : null,
                });
            }
        }
    };
};

//TODO: Piensar que hacer en caso que una de las ofertas enviadas este con error, igual segimos o como lo hacemos
export const setIsSimulatorOfferSended = (payload) => ({
    type: types.offerSetIsSimulatorOfferSended,
    payload,
});
export const setIsMantenimientoOfferSended = (payload) => ({
    type: types.offerSetIsMantenimientoOfferSended,
    payload,
});

export const setIsSeguroAutomovilOfferSended = (payload) => ({
    type: types.offerSetIsSeguroAutomovilOfferSended,
    payload,
});

export const setIsDownloadingCreditRequestDocument = (payload) => ({
    type: types.offerSetIsDownloadingCreditRequestDocument,
    payload,
});

export const setCreditRequestDocumentCode = (payload) => ({
    type: types.offerSetCreditRequestDocumentCode,
    payload,
});
