import { useContext, useState } from "react";
import {
    Layout,
    FormField,
    Input,
    Heading,
    RadioButtonGroup,
    RadioButton,
    Fieldset,
    Select,
} from "@vwfs-bronson/bronson-react";
import { validateEmptyField } from "../../../../validators/validateEmptyField";
import { useLocation } from "../../../../hooks/useLocation";
import { useEffect } from "react";
import { WizardConext } from "../../../../contexts/WizardContext";
import { spainIdType, validateSpanishID } from "../../../../validators/validateSpanishID";
import { checkIfIsNIE } from "../../../../helpers/checkIfIsNIE";
import { checkIfIsDNI } from "../../../../helpers/checkIfIsDNI";

export const Client = ({ formValuesClient, setHandleOnChangeClient, setValues }) => {
    const { isDirty } = useContext(WizardConext);
    const preventCopyPaste = (e) => {
        e.preventDefault();
    };
    const { Countries } = useLocation();
    const [listCountries, setListCountries] = useState(Countries);

    useEffect(() => {
        if (checkIfIsDNI(formValuesClient.NIF)) {
            setListCountries(Countries?.filter((x) => x.CountryCode === "ESP"));
            return;
        }

        if (checkIfIsNIE(formValuesClient.NIF)) {
            setListCountries(Countries?.filter((x) => x.CountryCode !== "ESP"));
            return;
        }

        setListCountries(Countries);
    }, [formValuesClient.NIF]);

    useEffect(() => {
        if (listCountries.length == 1)
            setValues({
                ...formValuesClient,
                Nacionality: listCountries[0].CountryCode,
            });
        else
            setValues({
                ...formValuesClient,
                Nacionality: "",
            });
    }, [listCountries]);

    useEffect(() => {
        if (validateSpanishID(formValuesClient.NIF)) {
            setValues({
                ...formValuesClient,
                TipoDocumento: spainIdType(formValuesClient.NIF),
            });
        }
    }, [formValuesClient.NIF]);

    return (
        <>
            <Fieldset.Row>
                <Layout>
                    <Layout.Item default="1/1">
                        <Heading level={5} className="u-text-left">
                            Datos Cliente
                        </Heading>
                    </Layout.Item>
                    <Layout.Item default="1/1" xs="1/1" className="u-mt-none u-mb">
                        <FormField labelElement="div" staticLabel type="other">
                            <RadioButtonGroup>
                                <RadioButton
                                    checked={formValuesClient.Sex === "H"}
                                    name="Sex"
                                    value="H"
                                    onChange={setHandleOnChangeClient}
                                >
                                    Sra.
                                </RadioButton>
                                <RadioButton
                                    checked={formValuesClient.Sex === "V"}
                                    name="Sex"
                                    value="V"
                                    onChange={setHandleOnChangeClient}
                                >
                                    Sr.
                                </RadioButton>
                            </RadioButtonGroup>
                        </FormField>
                    </Layout.Item>
                </Layout>
            </Fieldset.Row>
            <Fieldset.Row>
                <Layout>
                    <Layout.Item default="1/3" xs="1/1">
                        <FormField labelForId="Name" labelText="Nombre*" type="input">
                            <Input
                                id="Name"
                                name="Name"
                                placeholder="Nombre*"
                                autoComplete="nope"
                                defaultValue={formValuesClient.Name}
                                error={!validateEmptyField(formValuesClient.Name) && isDirty}
                                onChange={setHandleOnChangeClient}
                            />
                        </FormField>
                    </Layout.Item>
                    <Layout.Item default="1/3" xs="1/1">
                        <FormField labelForId="Surname1" labelText="Apellido1*" type="input">
                            <Input
                                id="Surname1"
                                name="Surname1"
                                placeholder="Apellido1*"
                                autoComplete="nope"
                                defaultValue={formValuesClient.Surname1}
                                error={!validateEmptyField(formValuesClient.Surname1) && isDirty}
                                onChange={setHandleOnChangeClient}
                            />
                        </FormField>
                    </Layout.Item>
                    <Layout.Item default="1/3" xs="1/1">
                        <FormField labelForId="Surname2" labelText="Apellido2*" type="input">
                            <Input
                                id="Surname2"
                                name="Surname2"
                                placeholder="Apellido2*"
                                autoComplete="nope"
                                defaultValue={formValuesClient.Surname2}
                                error={!validateEmptyField(formValuesClient.Surname2) && isDirty}
                                onChange={setHandleOnChangeClient}
                            />
                        </FormField>
                    </Layout.Item>
                </Layout>
            </Fieldset.Row>
            <Fieldset.Row>
                <Layout>
                    <Layout.Item default="1/3" xs="1/1">
                        <FormField labelForId="NIF" labelText="NIF/NIE*" type="input">
                            <Input
                                id="NIF"
                                name="NIF"
                                placeholder="NIF/NIE*"
                                autoComplete="nope"
                                defaultValue={formValuesClient.NIF}
                                error={!validateSpanishID(formValuesClient.NIF) && isDirty}
                                onChange={setHandleOnChangeClient}
                            />
                        </FormField>
                    </Layout.Item>
                    <Layout.Item default="1/3" xs="1/1">
                        <FormField labelForId="Nacionality" labelText="Nacionalidad*" type="select" noFloatingLabel>
                            <Select
                                id="Nacionality"
                                name="Nacionality"
                                defaultValue={formValuesClient.Nacionality}
                                error={!validateEmptyField(formValuesClient.Nacionality) && isDirty}
                                onChange={setHandleOnChangeClient}
                                disabled={listCountries.length === 1}
                            >
                                <Select.Item disabled value="">
                                    Seleccionar el país
                                </Select.Item>
                                {listCountries.map((country) => (
                                    <Select.Item key={country.CountryCode} value={country.CountryCode}>
                                        {country.CountryName}
                                    </Select.Item>
                                ))}
                            </Select>
                        </FormField>
                    </Layout.Item>
                    <Layout.Item default="1/3" xs="1/1">
                        <FormField labelForId="PhoneNumber" labelText="Teléfono móvil*" type="input">
                            <Input
                                id="PhoneNumber"
                                name="PhoneNumber"
                                type="tel"
                                placeholder="Teléfono móvil*"
                                autoComplete="nope"
                                pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                                defaultValue={formValuesClient.PhoneNumber}
                                error={!validateEmptyField(formValuesClient.PhoneNumber) && isDirty}
                                onChange={setHandleOnChangeClient}
                            />
                        </FormField>
                    </Layout.Item>
                </Layout>
            </Fieldset.Row>
            <Fieldset.Row>
                <Layout>
                    <Layout.Item>
                        <Heading level={6} className="u-text-left u-mb-none">
                            Importante, se debe introducir el e-mail del cliente a quién se le está relanzando la oferta
                        </Heading>
                    </Layout.Item>
                    <Layout.Item default="1/2" xs="1/1">
                        <FormField labelForId="Email" labelText="E-mail*" type="input">
                            <Input
                                id="Email"
                                name="Email"
                                placeholder="E-mail*"
                                autoComplete="nope"
                                pattern=".+@"
                                type="email"
                                defaultValue={formValuesClient.Email}
                                error={!validateEmptyField(formValuesClient.Email) && isDirty}
                                onChange={setHandleOnChangeClient}
                            />
                        </FormField>
                    </Layout.Item>
                    <Layout.Item default="1/2" xs="1/1">
                        <FormField labelForId="EmailConfirmation" labelText="Repita el e-mail*" type="input">
                            <Input
                                id="EmailConfirmation"
                                name="EmailConfirmation"
                                placeholder="Repita el e-mail*"
                                autoComplete="nope"
                                pattern=".+@"
                                type="email"
                                defaultValue={formValuesClient.EmailConfirmation}
                                error={
                                    (!validateEmptyField(formValuesClient.EmailConfirmation) ||
                                        formValuesClient.Email !== formValuesClient.EmailConfirmation) &&
                                    isDirty
                                }
                                onChange={setHandleOnChangeClient}
                                onCopy={(e) => preventCopyPaste(e)}
                                onPaste={(e) => preventCopyPaste(e)}
                                onCut={(e) => preventCopyPaste(e)}
                            />
                        </FormField>
                    </Layout.Item>
                </Layout>
            </Fieldset.Row>
        </>
    );
};
