import { FormSection, Layout, Table } from "@vwfs-bronson/bronson-react";
import { useSummary } from "../../../../hooks/useSummary";
import { financialFormat } from "../../../../helpers/financialFormat";
import { calculateHostDiscount } from "../../../../helpers/calculateHostDiscount";
import { useOffer } from "../../../../hooks/useOffer";
import { StepsNameEnum } from "../../../../types/enums";

export const SeguroAutomovilResume = () => {
    const { selectedSeguroAutoStandAlone } = useSummary();
    const { isSeguroAutomovilOfferSended } = useOffer();

    if (
        selectedSeguroAutoStandAlone?.SeguroContratado === null ||
        selectedSeguroAutoStandAlone?.SeguroContratado === undefined
    )
        return null;

    const TieneAportacion =
        selectedSeguroAutoStandAlone.SeguroContratado.grupoPaquetes.filter(
            (x) => x.Aportaciones[0]?.NumeroCuotasAportacion > 0
        ).length > 0
            ? true
            : false;

    const getSubtitle = () => {
        if (isSeguroAutomovilOfferSended === null || !isSeguroAutomovilOfferSended?.IsSended) {
            return <></>;
        }
        if (!isSeguroAutomovilOfferSended.ItsDone) {
            return <>No se ha podido generar oferta</>;
        }

        return <>{isSeguroAutomovilOfferSended.NumOfertaCliente}</>;
    };

    return (
        <Layout.Item className="u-mt-none">
            <FormSection
                defaultOpen={
                    isSeguroAutomovilOfferSended === null || !isSeguroAutomovilOfferSended?.IsSended ? true : false
                }
                subtitle={getSubtitle()}
                className="u-mb-small"
                success={isSeguroAutomovilOfferSended?.IsSended}
                error={isSeguroAutomovilOfferSended !== null && !isSeguroAutomovilOfferSended.IsSended}
                title={StepsNameEnum.SEGUROAUTOMOVIL}
            >
                <Table bordered colored>
                    <Table.Thead>
                        <Table.Tr>
                            <Table.Th />
                            <Table.Th className="u-p-none u-text-center">Tomador</Table.Th>
                            <Table.Th className="u-p-none u-text-center">Conductor Habitual</Table.Th>
                            <Table.Th className="u-p-none u-text-center">Conductor Ocasional</Table.Th>
                        </Table.Tr>
                    </Table.Thead>
                    <Table.Tbody testId="table-head-01">
                        <Table.Tr>
                            <Table.Th>NIF</Table.Th>
                            <Table.Td className="u-text-center">
                                {selectedSeguroAutoStandAlone?.Tomador?.NIF !== ""
                                    ? selectedSeguroAutoStandAlone?.Tomador?.NIF
                                    : "-"}
                            </Table.Td>
                            <Table.Td className="u-text-center">
                                {selectedSeguroAutoStandAlone?.ConductorHabitual?.NIF !== ""
                                    ? selectedSeguroAutoStandAlone?.ConductorHabitual?.NIF
                                    : "-"}
                            </Table.Td>
                            <Table.Td className="u-text-center">
                                {selectedSeguroAutoStandAlone?.CondutorOcasional?.NIF !== ""
                                    ? selectedSeguroAutoStandAlone?.CondutorOcasional?.NIF
                                    : "-"}
                            </Table.Td>
                        </Table.Tr>
                        <Table.Tr>
                            <Table.Th>Fecha de Nacimiento</Table.Th>
                            <Table.Td className="u-text-center">
                                {selectedSeguroAutoStandAlone?.Tomador?.FechaNacimiento !== ""
                                    ? selectedSeguroAutoStandAlone?.Tomador?.FechaNacimiento
                                    : "-"}
                            </Table.Td>
                            <Table.Td className="u-text-center">
                                {selectedSeguroAutoStandAlone?.ConductorHabitual?.FechaNacimiento !== ""
                                    ? selectedSeguroAutoStandAlone?.ConductorHabitual?.FechaNacimiento
                                    : "-"}
                            </Table.Td>
                            <Table.Td className="u-text-center">
                                {selectedSeguroAutoStandAlone?.CondutorOcasional?.FechaNacimiento !== ""
                                    ? selectedSeguroAutoStandAlone?.CondutorOcasional?.FechaNacimiento
                                    : "-"}
                            </Table.Td>
                        </Table.Tr>
                        <Table.Tr>
                            <Table.Th>Fecha de Carné</Table.Th>
                            <Table.Td className="u-text-center">
                                {selectedSeguroAutoStandAlone?.Tomador?.FechaCarne !== ""
                                    ? selectedSeguroAutoStandAlone?.Tomador?.FechaCarne
                                    : "-"}
                            </Table.Td>
                            <Table.Td className="u-text-center">
                                {selectedSeguroAutoStandAlone?.ConductorHabitual?.FechaCarne !== ""
                                    ? selectedSeguroAutoStandAlone?.ConductorHabitual?.FechaCarne
                                    : "-"}
                            </Table.Td>
                            <Table.Td className="u-text-center">
                                {selectedSeguroAutoStandAlone?.CondutorOcasional?.FechaCarne !== ""
                                    ? selectedSeguroAutoStandAlone?.CondutorOcasional?.FechaCarne
                                    : "-"}
                            </Table.Td>
                        </Table.Tr>
                        <Table.Tr>
                            <Table.Th>Código Postal</Table.Th>
                            <Table.Td className="u-text-center">
                                {selectedSeguroAutoStandAlone?.Tomador?.CodigoPostal !== ""
                                    ? selectedSeguroAutoStandAlone?.Tomador?.CodigoPostal
                                    : "-"}
                            </Table.Td>
                            <Table.Td className="u-text-center">
                                {selectedSeguroAutoStandAlone?.ConductorHabitual?.CodigoPostal !== ""
                                    ? selectedSeguroAutoStandAlone?.ConductorHabitual?.CodigoPostal
                                    : "-"}
                            </Table.Td>
                            <Table.Td className="u-text-center">
                                {selectedSeguroAutoStandAlone?.CondutorOcasional?.CodigoPostal !== ""
                                    ? selectedSeguroAutoStandAlone?.CondutorOcasional?.CodigoPostal
                                    : "-"}
                            </Table.Td>
                        </Table.Tr>
                        <Table.Tr>
                            <Table.Th>Provincia</Table.Th>
                            <Table.Td className="u-text-center">
                                {selectedSeguroAutoStandAlone?.Tomador?.Provincia !== ""
                                    ? selectedSeguroAutoStandAlone?.Tomador?.Provincia
                                    : "-"}
                            </Table.Td>
                            <Table.Td className="u-text-center">
                                {selectedSeguroAutoStandAlone?.ConductorHabitual?.Provincia !== ""
                                    ? selectedSeguroAutoStandAlone?.ConductorHabitual?.Provincia
                                    : "-"}
                            </Table.Td>
                            <Table.Td className="u-text-center">
                                {selectedSeguroAutoStandAlone?.CondutorOcasional?.Provincia !== ""
                                    ? selectedSeguroAutoStandAlone?.CondutorOcasional?.Provincia
                                    : "-"}
                            </Table.Td>
                        </Table.Tr>
                        <Table.Tr>
                            <Table.Th>Población</Table.Th>
                            <Table.Td className="u-text-center">
                                {selectedSeguroAutoStandAlone?.Tomador?.Poblacion !== ""
                                    ? selectedSeguroAutoStandAlone?.Tomador?.Poblacion
                                    : "-"}
                            </Table.Td>
                            <Table.Td className="u-text-center">
                                {selectedSeguroAutoStandAlone?.ConductorHabitual?.Poblacion !== ""
                                    ? selectedSeguroAutoStandAlone?.ConductorHabitual?.Poblacion
                                    : "-"}
                            </Table.Td>
                            <Table.Td className="u-text-center">
                                {selectedSeguroAutoStandAlone?.CondutorOcasional?.Poblacion !== ""
                                    ? selectedSeguroAutoStandAlone?.CondutorOcasional?.Poblacion
                                    : "-"}
                            </Table.Td>
                        </Table.Tr>
                        <Table.Tr>
                            <Table.Th>Cobertura Seleccionada</Table.Th>
                            <Table.Td className="u-text-center" colspan="3">
                                {selectedSeguroAutoStandAlone?.SeguroContratado?.ServiceName}
                            </Table.Td>
                        </Table.Tr>
                        <Table.Tr>
                            <Table.Th>Cuota</Table.Th>
                            <Table.Td className="u-text-center" colspan="3">
                                {financialFormat(
                                    selectedSeguroAutoStandAlone?.SeguroContratado?.grupoPaquetes[0]?.Paquetes[0]
                                        ?.ImporteNetoTarifa
                                )}
                                {" €/mes"}
                            </Table.Td>
                        </Table.Tr>
                        {TieneAportacion && (
                            <Table.Tr>
                                <Table.Th>Oferta Comercial</Table.Th>
                                <Table.Td className="u-text-center" colspan="3">
                                    {
                                        selectedSeguroAutoStandAlone?.SeguroContratado?.grupoPaquetes[0]
                                            ?.Aportaciones[0]?.NumeroCuotasAportacion
                                    }{" "}
                                    meses por{" "}
                                    {calculateHostDiscount(
                                        selectedSeguroAutoStandAlone?.SeguroContratado?.grupoPaquetes[0]
                                            ?.Aportaciones[0]?.ImporteNetoTarifa,
                                        selectedSeguroAutoStandAlone?.SeguroContratado?.grupoPaquetes[0]
                                            ?.Aportaciones[0]?.PorcentajeAportacion
                                    )}
                                    {" €/mes"}
                                </Table.Td>
                            </Table.Tr>
                        )}
                    </Table.Tbody>
                </Table>
            </FormSection>
        </Layout.Item>
    );
};
