import { postRequest } from "../config/axiosClient.js";
import AppService from "./AppService.js";

class VehicleService {
    constructor() {
        this.baseUrl = "vehicleservice";
    }

    getVehicle = async () => {
        return await postRequest(`${this.baseUrl}/getvehicle`, {});
    };

    getVehiclesForSaleLocalizations = async () => {
        return AppService.post(`${this.baseUrl}/getvehiclesforsalelocalizations`, {}, null, "core").then((response) => {
            return response;
        });
    };

    getVehiclesRenting = async (body) => {
        return AppService.post(`${this.baseUrl}/getvehiclesrenting`, body, null, "core").then((response) => {
            return response;
        });
    };

    getGroupBrands = async () => {
        return AppService.post(`${this.baseUrl}/getgroupbrands`, {}, null, "core").then((response) => {
            return response;
        });
    };

    getModelsByBrand = async (idbrand) => {
        return AppService.post(`${this.baseUrl}/getmodelsbybrand`, { Codigo: idbrand }, null, "core").then(
            (response) => {
                return response;
            },
        );
    };

    getImagesByVehicleID = async (vehicleId) => {
        return AppService.post(`${this.baseUrl}/getimagesbyvehicleid`, vehicleId, null, "core").then((response) => {
            return response;
        });
    };
}

export default VehicleService;
