import { postRequest } from "../config/axiosClient.js";

class OfferService {
    constructor() {
        this.baseUrl = "offerservice";
    }
    async getOffers() {
        return await postRequest(`${this.baseUrl}/getoffers`, {});
    }

    async removeOffers(body) {
        return await postRequest(`${this.baseUrl}/removeoffers`, body);
    }

    async setComparable(body) {
        return await postRequest(`${this.baseUrl}/setComparableOffers`, body);
    }

    async recoverOffers(body) {
        return await postRequest(`${this.baseUrl}/recover`, body);
    }

    async createOperation(body) {
        return await postRequest(`${this.baseUrl}/createoperationfromoffer`, body);
    }
    async getLegals(body) {
        return await postRequest(`${this.baseUrl}/getlegals`, body);
    }
    async createOffersFSOVLongDriveRelation(body) {
        return await postRequest(`${this.baseUrl}/createoffersfsovlongdriverelation`, body);
    }
    async saveProspectData(body) {
        return await postRequest(`${this.baseUrl}/updateOfferPropectData`, body);
    }
}

export default OfferService;
