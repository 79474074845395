import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import App from "./App";
import { store } from "./store/store";

import { ThemeSelector } from "./themes/ThemeSelector";
import { StrictMode } from "react";

createRoot(document.getElementById("root")).render(
    <StrictMode>
        <Provider store={store}>
            <ThemeSelector>
                <App />
            </ThemeSelector>
        </Provider>
    </StrictMode>
);
