import { Layout, ShowMore, IconList } from "@vwfs-bronson/bronson-react";
import { divideArrayInParts } from "../../../helpers/divideArrayInParts";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import AdditionalProductsService from "../../../services/AdditionalProductsService";
import { addNotificationError } from "../../../actions/notificationAction";

export const MantenimientoStandAloneShowMore = ({ code }) => {
    const [benefits, setBenefits] = useState([]);

    const dispatch = useDispatch();

    const readBenefits = async () => {
        let additionalProductsService = new AdditionalProductsService();
        const { data, status, errors } = await additionalProductsService.readProductsBenefitsLD(code.substring(0, 5));

        if (status !== 200) {
            dispatch(addNotificationError(errors));
            return;
        }

        setBenefits(divideArrayInParts(data, 3));
    };

    useEffect(() => {
        readBenefits();
    }, [code]);

    return (
        <Layout.Item className="u-mb u-mt-none">
            <ShowMore
                className="u-text-center"
                labelCollapse="Ver menos"
                labelExpand="¿Qué incluye?"
                onChange={function noRefCheck() {}}
            >
                <Layout>
                    {benefits.map((x, index) => (
                        <Layout.Item key={index} default={`1/${benefits.length}`}>
                            {x.map((x, index) => (
                                <IconList key={index}>
                                    <IconList.Item icon="semantic-checkmark" iconMeaning="Included:" success>
                                        {x}
                                    </IconList.Item>
                                </IconList>
                            ))}
                        </Layout.Item>
                    ))}
                </Layout>
            </ShowMore>
        </Layout.Item>
    );
};
