import { useEffect, useState } from "react";
import { useNotification } from "../../../hooks/useNotification";
import { TYPE_NOTIFICACION } from "../../../class/Notification";

import { Modal } from "@vwfs-bronson/bronson-react";
import { deleteNotification } from "../../../actions/notificationAction";
import { useDispatch } from "react-redux";

export const NotificationModal = () => {
    const { notifications } = useNotification();
    const dispatch = useDispatch();

    const [notificationsModal, setNotificationsModal] = useState([]);

    useEffect(() => {
        setNotificationsModal(
            notifications.filter((x) => x.Type === TYPE_NOTIFICACION.MODAL).map((x) => ({ ...x, Shown: true }))
        );
    }, [notifications]);

    const handleOnClose = (notification) => {
        dispatch(deleteNotification(notification));
    };

    return (
        <>
            {notificationsModal?.length > 0 && (
                <Modal
                    buttonCloseLabel="Close this dialog window."
                    buttonConfirmLabel="Close this dialog window."
                    buttonConfirmText="Aceptar"
                    buttonConfirmType="submit"
                    id="modalId"
                    onClickOutside={function noRefCheck() {}}
                    onClose={() => handleOnClose(notificationsModal[0])}
                    onConfirm={() => handleOnClose(notificationsModal[0])}
                    shown
                    title={notificationsModal[0].Title}
                >
                    {notificationsModal[0].Description}
                </Modal>
            )}
        </>
    );
};
