import { NotificationAlert } from "./NotificationAlert";
import { NotificationModal } from "./NotificationModal";
import { NotificationToast } from "./NotificationToast";

export const Notifications = () => {
    return (
        <>
            <NotificationAlert />
            <NotificationModal />
            <NotificationToast />
        </>
    );
};
