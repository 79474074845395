import { Navigate, Route, Routes } from "react-router-dom";
// import { FSOVManagementLayout } from "../hoc/FSOVManagementLayout";
import Initializer from "../hoc/Initializer";
import AuthPage from "../pages/AuthPage";
import { CheckoutPage } from "../pages/CheckoutPage";
import { ErrorPage } from "../pages/ErrorPage";
import { SearchPage } from "../pages/SearchPage";
// import { ImanPage } from "../pages/ImanPage";
import { lazy } from "react";
import { WizardPage } from "../pages/WizardPage";

const FSOVManagementLayoutLazy = lazy(() => import("../hoc/FSOVManagementLayout"));

const SimulatorLayoutLazy = lazy(() => import("../hoc/SimulatorLayout"));

export const GeneralRoutes = () => {
    return (
        <Routes>
            <Route element={<Initializer />}>
                <Route path="/auth/:token" element={<AuthPage />} />
                {/* <Route path="/iman" element={<ImanPage />} /> */}
                <Route path="/checkout/:token" element={<CheckoutPage />} />
                <Route path="/dashboard/*" element={<FSOVManagementLayoutLazy />} />
                <Route path="/simulator/*" element={<SimulatorLayoutLazy />} />
                <Route path="/wizard" element={<WizardPage />} />
                <Route path="/search" element={<SearchPage />} />
                <Route path="/error" element={<ErrorPage />} />
                <Route path="*" element={<Navigate to={"/error"} replace />} />
            </Route>
        </Routes>
    );
};
