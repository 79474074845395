import { Layout, FormField, Input, RadioButtonGroup, RadioButton, Fieldset } from "@vwfs-bronson/bronson-react";

export const ClientResume = ({ clientData }) => {
    return (
        <>
            <Fieldset.Row>
                <Layout>
                    <Layout.Item default="1/1" xs="1/1" className="u-mb">
                        <FormField labelElement="div" staticLabel type="other">
                            <RadioButtonGroup>
                                <RadioButton checked={clientData.Sex === "H"} name="Sex" value="F" readOnly>
                                    Sra.
                                </RadioButton>
                                <RadioButton checked={clientData.Sex === "V"} name="Sex" value="M" readOnly>
                                    Sr.
                                </RadioButton>
                            </RadioButtonGroup>
                        </FormField>
                    </Layout.Item>
                </Layout>
            </Fieldset.Row>
            <Fieldset.Row>
                <Layout>
                    <Layout.Item default="1/3" xs="1/1">
                        <FormField labelForId="Name" labelText="Nombre*" type="input">
                            <Input
                                id="Name"
                                name="Name"
                                placeholder="Nombre*"
                                defaultValue={clientData.Name}
                                readOnly
                            />
                        </FormField>
                    </Layout.Item>
                    <Layout.Item default="1/3" xs="1/1">
                        <FormField labelForId="Surname1" labelText="Apellido1*" type="input">
                            <Input placeholder="Apellido1*" defaultValue={clientData.Surname1} readOnly />
                        </FormField>
                    </Layout.Item>
                    <Layout.Item default="1/3" xs="1/1">
                        <FormField labelForId="Surname2" labelText="Apellido2*" type="input">
                            <Input placeholder="Apellido2*" defaultValue={clientData.Surname2} readOnly />
                        </FormField>
                    </Layout.Item>
                </Layout>
            </Fieldset.Row>
            <Fieldset.Row>
                <Layout>
                    <Layout.Item default="1/3" xs="1/1">
                        <FormField labelForId="NIF" labelText="NIF*" type="input">
                            <Input placeholder="NIF*" defaultValue={clientData.NIF} readOnly />
                        </FormField>
                    </Layout.Item>
                    <Layout.Item default="1/3" xs="1/1">
                        <FormField labelForId="Nacionality" labelText="Nacionalidad*" type="input">
                            <Input placeholder="Nacionalidad*" defaultValue={clientData.Nacionality} readOnly />
                        </FormField>
                    </Layout.Item>
                    <Layout.Item default="1/3" xs="1/1">
                        <FormField labelForId="PhoneNumber" labelText="Teléfono móvil*" type="input">
                            <Input
                                type="tel"
                                placeholder="Teléfono móvil*"
                                defaultValue={clientData.PhoneNumber}
                                readOnly
                            />
                        </FormField>
                    </Layout.Item>
                </Layout>
            </Fieldset.Row>
            <Fieldset.Row>
                <Layout>
                    <Layout.Item default="1/1" xs="1/1">
                        <FormField labelForId="Email" labelText="E-mail*" type="input">
                            <Input placeholder="E-mail*" type="email" defaultValue={clientData.Email} readOnly />
                        </FormField>
                    </Layout.Item>
                </Layout>
            </Fieldset.Row>
        </>
    );
};
