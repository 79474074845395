export const types = {
    authGetIsAuthenticated: "[Auth] Get is authenticated",
    authSetIsAuthenticated: "[Auth] Set is authenticated",
    authError: "[Auth] Error",
    authLogOut: "[Auth] Logout",

    clientGetClient: "[Client] Get client",
    clientSetIncludeTax: "[Client] Set include tax",
    clientLoading: "[Client] Set loading",
    clientError: "[Client] Set error",
    clientSetClientData: "[Client] Set client data",

    globalSetCalculatorType: "[Global] Set calculator type",
    globalSetMaxOffersToSend: "[Global] Set max offers to send",
    globalSetIsStarted: "[Global] Set is started",
    globalSetisPricesWithTax: "[Global] Set Price with taxes",
    globalSetHasAccessToLongDrive: "[Global] Set access to longdrive",
    globalSetreturnSalesassistInfo: "[Global] Set Salesassist info",
    globalSetIsSalesAssist: "[Global] Set if it's Salesassist",
    globalSetDownPaymentError: "[Global] Set the value for down payment error (true or false)",

    globalSetShowProspectData: "[Global] Set show prospect data",
    globalSetSaveProspectData: "[Global] Set save prospect data",

    notificationAdd: "[Notification] Add notification",
    notificationDelete: "[Notification] Delete notification",

    summarySetSelectedProductFamily: "[Summary] Set selected product family",
    summarySetSelectedToken: "[Summary] Set selected token",
    summarySetSelectedPlan: "[Summary] Set selected plan",
    summarySetSelectedProduct: "[Summary] Set selected product",
    summarySetSelectedCampain: "[Summary] Set selected campain",
    summarySetSelectedMeses: "[Summary] Set selected meses",
    summarySetSelectedKms: "[Summary] Set selected kms",
    summarySetSelectedPrecio: "[Summary] Set selected precio",
    summarySetSelected: "[Summary] Set selected precio",
    summarySetSelectedIsInitAmount: "[Summary] Set selected is init amount",
    summarySetSelectedTires: "[Summary] Set selected tires",
    summarySetSelectedServices: "[Summary] Set selected services",
    summarySetSelectedOffers: "[Summary] Set selected offers",
    summarySetSelectedAdditionalProducts: "[Summary] Set selected Additional Products",
    summarySetSelectedAdditionalProductsData: "[Summary] Set selected Additional Products Data",
    summaryResetData: "[Summary] Reset data",
    summarySetSelectedResumeOffers: "[Summary] Set resume offers",
    summarySetSelectedMantenimentoStandAlone: "[Summary] Set Mantenimiento StandAlone",
    summarySetSelectedSeguroAutoStandAlone: "[Summary] Set Seguro auto StandAlone",
    summarySetSelectedClientStandAlone: "[Summary] Set Client StandAlone",

    offerSetOffers: "[Offer] Set offers",
    offerSetSimulations: "[Offer] Set simulations",
    offerSetModalDetailSelected: "[Offer] Set simulations to modal details",
    offerAddSimulation: "[Offer] Add simulation",
    offerRemoveSimulation: "[Offer] Remove simulation",
    offerSetIsCalculating: "[Offer] Set is calculating",
    offerSetIsLoading: "[Offer] Set loading",
    offerSetIsSendingOffers: "[Offer] Set loading when sending offers",

    offerSetShowComparatorModal: "[Offer] Set show comparator modal",
    offerAddComparableOffer: "[Offer] Add comparable offer",
    offerRemoveComparableOffer: "[Offer] Remove comparable offer",

    offerSetIsSimulatorOfferSended: "[Offer] Set is Simulator Offer Sended",
    offerSetIsMantenimientoOfferSended: "[Offer] set is Mantenimiento Offer Sendedr",
    offerSetIsSeguroAutomovilOfferSended: "[Offer] Set is Seguro Automovil Offer Sended",

    offerSetIsDownloadingCreditRequestDocument: "[Offer] Set loading when downloading Credit Request Document",
    offerSetCreditRequestDocumentCode: "[Offer] Set Credit Request Document file code",

    productSetProducts: "[Products] Set products",
    productSetLoading: "[Products] Set loading",

    campainSetCampains: "[Campain] Set campains",
    campainSetLoading: "[Campain] Set loading",

    serviceSetServices: "[Service] Set services",
    serviceSetLoading: "[Service] Set loading",

    vehicleSetVehicle: "[Vehicle] Set vehicle",

    addictionalProductsSetAdditionalProductsList: "[AddictionalProducts] Set Additional Products List",
    addictionalProductsSetAdditionalProducts: "[AddictionalProducts] Set Additional Products",
    addictionalProductsSetOpenTab: "[AddictionalProducts] Set Tab Open in Modal",
    addictionalProductsShowModalAddProd: "[AddictionalProducts] Show Modal Additional products",
    addictionalProductsMantenimientoStandAlone: "[AddictionalProducts] Set Mantenimiento Stand Alone",
    addictionalProductsSeguroAutomovilStandAlone: "[AddictionalProducts] Set Seguro Automovil Stand Alone",

    wizardSetSteps: "[Wizard] Set Tab Selected",
    wizardSetAlreadyEnterInWizard: "[Wizard] Set Already Enter in Wizard",

    locationGetAllCountries: "[Location] Get All Countries",
    locationGetAllStreetTypes: "[Location] Get All Street Types",

    insuranceSetTarifasZurich: "[Insurance] Set tarigads zurich",
};

export const FAMILY_PRODUCT = {
    FINANCING: "FINANCING",
    RENTING: "RENTING",
    LEASING: "LEASING",
};

export const PRODUCT_CODE = {
    C5: "C5",
    CP: "CP",
    LI: "LI",
    NONE: "NONE",
    RNT: "RNT", // no existe como producto pero a nuestros fines englobamos todos los renting en este code.
    LE: "LE", // no existe como producto pero a nuestros fines englobamos todos los leasing en este code.
};

export const CLIENT_TYPE_KEY = {
    PHYSICAL: "F",
    LEGAL: "J",
};
