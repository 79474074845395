import { postRequest } from "../config/axiosClient";
import { types } from "../types/types";
import { addNotificationError } from "./notificationAction";
import { getLegalsSelectedResumeOffers } from "../actions/offerAction.js";
import OfferService from "../services/OfferService";
export const redirectCheckout = (token) => {
    return async (dispatch) => {
        const { data, status, errors } = await postRequest("fsoneviewservice/checkout", token);

        if (status !== 200) {
            dispatch(addNotificationError(errors));
            return;
        }

        if (data?.Codigo) {
            window.location.href = data.Codigo;
        }
    };
};

export const setMaxOffersToSend = (payload) => ({
    type: types.globalSetMaxOffersToSend,
    payload: payload,
});

export const setCalculatorType = (payload) => ({
    type: types.globalSetCalculatorType,
    payload: payload,
});

export const setIsStarted = (payload) => ({
    type: types.globalSetIsStarted,
    payload: payload,
});

export const setIsPricesWithTax = (payload) => ({
    type: types.globalSetisPricesWithTax,
    payload: payload,
});

export const setHasAccessToLongDrive = (payload) => ({
    type: types.globalSetHasAccessToLongDrive,
    payload: payload,
});

export const setReturnSalesassistInfo = (payload) => ({
    type: types.globalSetreturnSalesassistInfo,
    payload: payload,
});

export const setIsSalesAssist = (payload) => ({
    type: types.globalSetIsSalesAssist,
    payload: payload,
});

export const setDownPaymentError = (payload) => ({
    type: types.globalSetDownPaymentError,
    payload: payload,
});

export const generateOffers = (offersToGenerate) => {
    return async (dispatch) => {
        dispatch(getLegalsSelectedResumeOffers(offersToGenerate));
        window.location.href = "/#/wizard";
    };
};

export const setProspectData = (payload) => ({
    type: types.globalSetSaveProspectData,
    payload: payload,
});

export const setShowProspectData = (payload) => ({
    type: types.globalSetShowProspectData,
    payload: payload,
});

export const saveProspectData = (offer) => {
    return async (dispatch, getState) => {
        try {
            const { prospectData, mustSaveProspectData } = getState().global;
            const { client } = getState().client;

            let requestBody = {
                clientType: client.Class.Key,
                cod_offer: offer.Offer.CodigoOferta,
            };
            if (mustSaveProspectData) {
                requestBody = {
                    ...requestBody,
                    ...prospectData,
                };
            }
            const offerService = new OfferService();
            offerService.saveProspectData(requestBody);
        } catch (error) {
            console.log("Error saving prospect data", "\nError: ", error);
        } finally {
            dispatch(setShowProspectData({ show: false, offer: null, mustSaveProspectData: false }));
        }
    };
};
