import { PRODUCT_CODE } from "../types/types";

const UtilitiesService = {
    financial(value, decimals = 2) {
        if (!value) {
            value = "000";
        }
        value = value.toString();
        while (value.length <= decimals) {
            value = "0" + value;
        }

        let valuePart1 = value.substring(0, value.length - decimals);
        let valuePart2 = value.substring(value.length - decimals);

        return valuePart1.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.") + (decimals !== 0 ? "," + valuePart2 : "");
    },
    numb(value) {
        if (value) {
            value = value.toString();
            return value.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
        } else return "0";
    },
    todigit(value) {
        if (!value) {
            value = "0";
        }
        value = value.toString();
        return value.replace(/\./g, "");
    },
    capitalizefirstletter(word) {
        if (word) {
            return word[0].toUpperCase() + word.slice(1).toLowerCase();
        } else {
            return "";
        }
    },
    formatdate(date) {
        if (!UtilitiesService.isNullOrUndefined(date)) {
            let dateaux = new Date(date);
            var dd = dateaux.getDate();
            var mm = dateaux.getMonth() + 1;
            var yyyy = dateaux.getFullYear();
            if (dd < 10) {
                dd = "0" + dd;
            }

            if (mm < 10) {
                mm = "0" + mm;
            }
            dateaux = dd + "/" + mm + "/" + yyyy;

            return dateaux;
        } else {
            return "Sin fecha";
        }
    },
    convertToDate(DDMMYYYY) {
        let arraydate = DDMMYYYY.split("/");
        if (arraydate.length > 2)
            return arraydate[2].toString() + "-" + arraydate[1].toString() + "-" + arraydate[0].toString();
        else return "";
    },
    getCookie(name) {
        var value = "; " + document.cookie;
        var parts = value.split("; " + name + "=");
        if (parts.length === 2) return parts.pop().split(";").shift();
    },
    isRVO() {
        return localStorage.RouteFrom === "RVO";
    },
    isRenting(productCode) {
        return productCode !== PRODUCT_CODE.LI && productCode !== PRODUCT_CODE.CP && productCode !== PRODUCT_CODE.C5;
    },
    isNullOrUndefined(value) {
        return value === null || value === undefined || value === "";
    },
    updateObjectReducer(oldObject, updatedProperties) {
        return {
            ...oldObject,
            ...updatedProperties,
        };
    },
    getFeePerMonth(offer, showTax) {
        return UtilitiesService.financial(parseInt(offer.FeeAmount) + (showTax ? parseInt(offer.FeeTax) : 0));
    },
    b64toBlob(b64Data, contentType = "", sliceSize = 512) {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, { type: contentType });
        return blob;
    },
    urlToBase64Img(url, callback) {
        var xhr = new XMLHttpRequest();
        xhr.onload = function () {
            var reader = new FileReader();
            reader.onloadend = function () {
                callback(reader.result);
            };
            reader.readAsDataURL(xhr.response);
        };
        xhr.open("GET", url);
        xhr.responseType = "blob";
        xhr.send();
    },
    urlToBase64ImgPromise(url) {
        return new Promise((resolve) => {
            var xhr = new XMLHttpRequest();
            xhr.onload = function () {
                var reader = new FileReader();
                reader.onloadend = function () {
                    resolve(reader.result);
                };
                reader.readAsDataURL(xhr.response);
            };
            xhr.open("GET", url);
            xhr.responseType = "blob";
            xhr.send();
        });
    },
    validateJSON(str) {
        try {
            JSON.parse(str);
        } catch {
            return false;
        }
        return true;
    },
};
export default UtilitiesService;
