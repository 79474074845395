import { lazy, Suspense } from "react";

/**
 * The theme components only imports it's theme CSS-file. These components are lazy
 * loaded, to enable "code splitting" (in order to avoid the themes being bundled together)
 */
const VW6 = lazy(() => import("./vw6"));
const LCV = lazy(() => import("./lcv"));
const AUDI = lazy(() => import("./audi"));
const SKODA = lazy(() => import("./skoda"));
const SEAT = lazy(() => import("./seat"));
const BLUELABEL = lazy(() => import("./bluelabel"));
const GENERIC = lazy(() => import("./generic"));
const CUPRA = lazy(() => import("./cupra"));

export const ThemeSelector = ({ children }) => {
    return (
        <>
            <Suspense fallback={<></>}>
                {(import.meta.env.VITE_APP_BRONSON_BRAND === "vw6" ||
                    import.meta.env.VITE_APP_BRONSON_BRAND === "vw") && <VW6>{children}</VW6>}
                {import.meta.env.VITE_APP_BRONSON_BRAND === "lcv" && <LCV>{children}</LCV>}
                {import.meta.env.VITE_APP_BRONSON_BRAND === "audi" && <AUDI>{children}</AUDI>}
                {import.meta.env.VITE_APP_BRONSON_BRAND === "skoda" && <SKODA>{children}</SKODA>}
                {import.meta.env.VITE_APP_BRONSON_BRAND === "seat" && <SEAT>{children}</SEAT>}
                {import.meta.env.VITE_APP_BRONSON_BRAND === "bluelabel" && <BLUELABEL>{children}</BLUELABEL>}
                {import.meta.env.VITE_APP_BRONSON_BRAND === "generic" && <GENERIC>{children}</GENERIC>}
                {import.meta.env.VITE_APP_BRONSON_BRAND === "cupra" && <CUPRA>{children}</CUPRA>}
            </Suspense>
        </>
    );
};
