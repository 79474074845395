import { useSummary } from "../../../hooks/useSummary";

import { Layout } from "@vwfs-bronson/bronson-react";

export const NotSelectedResume = () => {
    const { selectedResumeOffers, selectedMantenimentoStandAlone, selectedSeguroAutoStandAlone } = useSummary();
    if (
        selectedResumeOffers?.length > 0 ||
        selectedMantenimentoStandAlone ||
        selectedSeguroAutoStandAlone?.selectedMantenimentoStandAlone
    )
        return null;
    return <Layout.Item>No se ha seleccionado ningún producto</Layout.Item>;
};
