import { useContext } from "react";
import { WizardConext } from "../../contexts/WizardContext";
import { Layout } from "@vwfs-bronson/bronson-react";

export const WizardButtons = () => {
    const { backButton, leaveButton, nextButton } = useContext(WizardConext);

    return (
        <Layout>
            <Layout.Item default="1/3">{backButton}</Layout.Item>
            <Layout.Item default="1/3">
                <div className="u-text-center" id="NextButton">
                    {nextButton}
                </div>
            </Layout.Item>
            <Layout.Item default="1/3">
                <div className="u-text-right">{leaveButton}</div>
            </Layout.Item>
        </Layout>
    );
};
