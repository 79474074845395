import { useContext, useEffect } from "react";
import { Layout, Fieldset, FormField, Input, DatePicker } from "@vwfs-bronson/bronson-react";
import { convertMomentjsDateFormatToFlactPicker } from "../../../../helpers/convertMomentjsDateFormatToFlactPicker";
import moment from "moment";

const region = "es";
moment.locale(region.substring(0, 2));
import { useDispatch } from "react-redux";
import { getProvinceAndMunicipalityByZipCode } from "../../../../actions/locationAction";
import { validateEmptyField } from "../../../../validators/validateEmptyField";
import { WizardConext } from "../../../../contexts/WizardContext";
import { spainIdType, validateSpanishID } from "../../../../validators/validateSpanishID";
import { validateBirthDay } from "../../../../validators/validateBirthDay";
import { validateLicenceDate } from "../../../../validators/validateLicenceDate";
import { validateZipCode } from "../../../../validators/validateZipCode";

export const ClientInfo = ({ formValues, handleOnChange, setValues }) => {
    const dispatch = useDispatch();

    const { isDirty } = useContext(WizardConext);

    useEffect(() => {
        async function fetchData() {
            const info = await getProvinceAndMunicipalityByZipCode(formValues.CodigoPostal, dispatch);
            if (info !== null && info !== undefined) {
                //Update province and municipe fields
                setValues({
                    ...formValues,
                    Provincia: info.ProvinceDescription,
                    Poblacion: info.MunicipalityDescription,
                    IdProvince: info.ProvinceCode,
                    IdPoblacion: info.MunicipalityCode,
                });
            }
        }
        if (validateZipCode(formValues.CodigoPostal)) {
            fetchData();
        }
    }, [formValues.CodigoPostal]);

    useEffect(() => {
        if (validateSpanishID(formValues.NIF)) {
            setValues({
                ...formValues,
                TipoDocumento: spainIdType(formValues.NIF),
            });
        }
    }, [formValues.NIF]);

    return (
        <>
            <Fieldset.Row>
                <Layout>
                    <Layout.Item default="1/3" xs="1/1">
                        <FormField labelForId="NIFNIE" labelText="NIF/NIE*" type="input">
                            <Input
                                id="NIF"
                                name="NIF"
                                placeholder="NIF/NIE"
                                autoComplete="nope"
                                value={formValues.NIF.toUpperCase()}
                                error={!validateSpanishID(formValues.NIF) && isDirty}
                                onChange={(e) =>
                                    handleOnChange({
                                        target: {
                                            value: e.target.value.toUpperCase(),
                                            name: "NIF",
                                        },
                                    })
                                }
                            />
                        </FormField>
                    </Layout.Item>
                    <Layout.Item default="1/3" xs="1/1">
                        <FormField labelForId="FechaNacimiento" labelText="Fecha de Nacimiento*" type="other">
                            <DatePicker
                                dateFormat={convertMomentjsDateFormatToFlactPicker(
                                    moment.localeData().longDateFormat("L")
                                )}
                                maxDate={moment().subtract(18, "y").toDate()}
                                id="FechaNacimiento"
                                name="FechaNacimiento"
                                autoComplete="nope"
                                error={!validateBirthDay(formValues.FechaNacimiento) && isDirty}
                                value={formValues.FechaNacimiento}
                                onChange={(e) =>
                                    handleOnChange({
                                        target: { value: e, name: "FechaNacimiento" },
                                    })
                                }
                                onBlur={(e) => {
                                    handleOnChange({
                                        target: { value: e, name: "FechaNacimiento" },
                                    });
                                }}
                                locale={region.substring(0, 2)}
                            />
                        </FormField>
                    </Layout.Item>
                    <Layout.Item default="1/3" xs="1/1">
                        <FormField labelForId="FechaCarne" labelText="Fecha de Carnet*" type="other">
                            <DatePicker
                                dateFormat={convertMomentjsDateFormatToFlactPicker(
                                    moment.localeData().longDateFormat("L")
                                )}
                                maxDate={moment().toDate()}
                                id="FechaCarne"
                                name="FechaCarne"
                                autoComplete="nope"
                                error={
                                    !validateLicenceDate(formValues.FechaNacimiento, formValues.FechaCarne) && isDirty
                                }
                                value={formValues.FechaCarne}
                                onChange={(e) => handleOnChange({ target: { value: e, name: "FechaCarne" } })}
                                locale={region.substring(0, 2)}
                            />
                        </FormField>
                    </Layout.Item>
                </Layout>
            </Fieldset.Row>
            <Fieldset.Row>
                <Layout>
                    <Layout.Item default="1/3" xs="1/1">
                        <FormField labelForId="CodigoPostal" labelText="Código Postal*" type="input">
                            <Input
                                id="CodigoPostal"
                                placeholder="Código Postal"
                                name="CodigoPostal"
                                autoComplete="nope"
                                error={
                                    !validateZipCode(formValues.ZipCode) &&
                                    validateEmptyField(formValues.ZipCode) &&
                                    isDirty
                                }
                                value={formValues.CodigoPostal}
                                onChange={handleOnChange}
                            />
                        </FormField>
                    </Layout.Item>
                    <Layout.Item default="1/3" xs="1/1">
                        <FormField labelForId="Provincia" labelText="Provincia*" type="input">
                            <Input
                                id="Provincia"
                                name="Provincia"
                                placeholder="Provincia"
                                autoComplete="nope"
                                value={formValues.Provincia}
                                onChange={handleOnChange}
                                readOnly
                            />
                        </FormField>
                    </Layout.Item>
                    <Layout.Item default="1/3" xs="1/1">
                        <FormField labelForId="Poblacion" labelText="Población*" type="input">
                            <Input
                                id="Poblacion"
                                name="Poblacion"
                                placeholder="Población"
                                autoComplete="nope"
                                value={formValues.Poblacion}
                                onChange={handleOnChange}
                                readOnly
                            />
                        </FormField>
                    </Layout.Item>
                </Layout>
            </Fieldset.Row>
        </>
    );
};
