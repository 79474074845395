import moment from "moment/moment";

export const adapterClientDateFormat = (client) => {
    const region = "es";
    moment.locale(region);
    var result = JSON.parse(JSON.stringify(client));

    result.FechaNacimiento = moment(client.FechaNacimiento, "DD/MM/YYYY").format("YYYY/MM/DD");
    result.FechaEfecto = moment(client.FechaEfecto, "DD/MM/YYYY").format("YYYY/MM/DD");
    result.FechaCarne = moment(client.FechaCarne, "DD/MM/YYYY").format("YYYY/MM/DD");

    return result;
};
