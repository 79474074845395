import { useState } from "react";
import { useEffect } from "react";
import { Outlet, useSearchParams } from "react-router-dom";
import { Notifications } from "../components/UI/Notifications/Notifications";

function Initializer() {
    const [searchParams] = useSearchParams();
    const [isReturnFired, setIsReturnFired] = useState(null);
    useEffect(() => {
        if (searchParams.get("XmlRet")) {
            setIsReturnFired(true);
            const XmlRet = {
                Type: "ResponseFired",
                Data: searchParams.get("XmlRet"),
            };

            window.parent.postMessage(XmlRet, "*");
        } else {
            setIsReturnFired(false);
        }
    }, [searchParams]);

    return (
        <>
            {isReturnFired === false && (
                <>
                    <Notifications></Notifications>
                    <Outlet />
                </>
            )}
        </>
    );
}

export default Initializer;
