import Utility from "./../../services/UtilitiesService";

export const handleError = (state, action) => {
    return Utility.updateObjectReducer(state, {
        error: action.payload,
        loading: false,
    });
};

export const loading = (state, action) => {
    return Utility.updateObjectReducer(state, {
        loading: action.payload ?? true,
        error: null,
    });
};

export const setSelected = (state, action) => {
    return Utility.updateObjectReducer(state, {
        selected: action.payload,
        error: null,
    });
};
