import { Layout, Heading, Paragraph } from "@vwfs-bronson/bronson-react";

export const ErrorPage = () => {
    return (
        <Layout center className="u-mt-xlarge">
            <Layout.Item default="1/1">
                <Heading level={5} className="u-text-center u-mb-xlarge  u-text-alert">
                    No se ha podido procesar la petición.
                </Heading>
                <Paragraph className="u-text-center u-mb-none">
                    El error puede deberse a un problema con la red.<br></br>
                    Si lo desea, puede intentar volver a iniciar la aplicación haciendo click aquí.
                </Paragraph>
            </Layout.Item>
        </Layout>
    );
};
