import { Main, ContentSection, Wrapper, Layout, Spinner } from "@vwfs-bronson/bronson-react";
import { useDispatch } from "react-redux";
import { useOffer } from "../hooks/useOffer";
import { setAlreadyEnterInWizard } from "../actions/wizardAction";
import { useEffect } from "react";
import { resetProduct } from "../actions/productAction";
import { WizardResumen } from "../components/Wizard/WizardResumen";
import { WizardProvider } from "../contexts/WizardContext";
import { WizardButtons } from "../components/Wizard/WizardButtons";
import { ModalInfoStandAlone } from "../components/Modals/ModalInfoStandAlone";
import { useGlobals } from "../hooks/useGlobals";
import { CalculatorTypeEnum } from "../types/enums";
import { getVehicle } from "../actions/vehicleAction";

export const WizardPage = () => {
    const dispatch = useDispatch();
    const { isSendingOffers, isCalculating } = useOffer();
    const { calculatorType } = useGlobals();

    useEffect(() => {
        if (
            calculatorType !== CalculatorTypeEnum.RENTING &&
            calculatorType !== CalculatorTypeEnum.FINANCE &&
            calculatorType !== CalculatorTypeEnum.LEASING
        )
            dispatch(getVehicle());
        dispatch(resetProduct());
        dispatch(setAlreadyEnterInWizard(true));
    }, []);

    return (
        <Main>
            <ContentSection pageWrapSize="medium">
                <Wrapper>
                    <Layout>
                        <WizardProvider>
                            <WizardResumen></WizardResumen>
                            <Layout.Item></Layout.Item>
                            <Layout.Item default="1/1">
                                <WizardButtons></WizardButtons>
                            </Layout.Item>
                            <ModalInfoStandAlone></ModalInfoStandAlone>
                        </WizardProvider>
                    </Layout>
                </Wrapper>
            </ContentSection>
            <Spinner busy={isCalculating} fullPage></Spinner>
            <Spinner busy={isSendingOffers} fullPage>
                <p>Estamos generando la oferta. Por favor, espere...</p>
            </Spinner>
        </Main>
    );
};
