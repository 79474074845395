import { applyMiddleware, compose, createStore } from "redux";
import { combineReducers } from "redux";
import { thunk } from "redux-thunk";
import { authReducer } from "../reducers/authReducer";
import campainReducer from "../reducers/campainReducer";
import { clientReducer } from "../reducers/clientReducer";
import { globalReducer } from "../reducers/globalReducer";

import { notificationReducer } from "../reducers/notificationReducer";
import offerReducer from "../reducers/offerReducer";
import { planReducer } from "../reducers/planReducer";
import { productReducer } from "../reducers/productReducer";
import serviceReducer from "../reducers/serviceReducer";
import { summaryReducer } from "../reducers/summaryReducer";
import vehicleReducer from "../reducers/vehicleReducer";
import addictionalProductsReducer from "../reducers/addictionalProductsReducer";
import { wizardReducer } from "../reducers/wizardReducer";
import { locationsReducer } from "../reducers/locationsReducer";
import insuranceReducer from "../reducers/insuranceReducer";

const reducers = combineReducers({
    notification: notificationReducer,
    products: productReducer,
    global: globalReducer,
    plan: planReducer,
    summary: summaryReducer,
    campain: campainReducer,
    service: serviceReducer,
    auth: authReducer,
    client: clientReducer,
    offer: offerReducer,
    vehicle: vehicleReducer,
    addicionalProducts: addictionalProductsReducer,
    wizard: wizardReducer,
    location: locationsReducer,
    insurance: insuranceReducer,
});

const composeEnchancers = (typeof window !== "undefined" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

export const store = createStore(reducers, composeEnchancers(applyMiddleware(thunk)));
