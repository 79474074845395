import { Card, Layout, Button, Spinner } from "@vwfs-bronson/bronson-react";
import { useOffer } from "../../hooks/useOffer";

export const SearchOperation = ({
    operation,
    offerCode,
    generateCreditRequestDocument,
    downloadCreditRequestDocument,
}) => {
    const { isDownloadingCreditRequestDocument, creditRequestDocumentCode } = useOffer();

    const onClickDownloadCreditRequestDocument = () => {
        if (creditRequestDocumentCode) {
            downloadCreditRequestDocument(creditRequestDocumentCode);
        } else {
            generateCreditRequestDocument(operation, offerCode);
        }
    };

    return (
        <Layout center className="u-mt-xxsmall">
            <Layout.Item default="1/3" l="1/2" m="1/1">
                <Card>
                    <Layout>
                        <Layout.Item className="u-text-center">
                            <h6>Código operación</h6>
                            <h4>{operation}</h4>
                            <div style={{ position: "relative" }}>
                                <Button
                                    onClick={onClickDownloadCreditRequestDocument}
                                    disabled={isDownloadingCreditRequestDocument}
                                >
                                    Descargar Solicitud de Crédito
                                </Button>
                                <Spinner busy={isDownloadingCreditRequestDocument} />
                            </div>
                        </Layout.Item>
                    </Layout>
                </Card>
            </Layout.Item>
        </Layout>
    );
};
