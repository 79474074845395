import { useEffect } from "react";

import {
    Wizard,
    Layout,
    Form,
    Fieldset,
    Heading,
    FormField,
    RadioButtonGroup,
    RadioButton,
    ButtonContainer,
    Button,
} from "@vwfs-bronson/bronson-react";
import { ClientInfo } from "./ClientInfoStep/ClientInfo";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { setSelectedSeguroAutoStandAlone } from "../../../actions/summaryAction";

import Notification, { SEVERITY_NOTIFICACION, TYPE_NOTIFICACION } from "../../../class/Notification";
import { addNotification } from "../../../actions/notificationAction";
import { GetTarificacionZurichLongDrive, setSeguroStandAlone } from "../../../actions/insuranceAction";
import { TomadorInfo } from "./ClientInfoStep/TomadorInfo";
import { useInsurance } from "../../../hooks/useInsurance";
import { Coberturas } from "./Coberturas/Coberturas";
import { validateSpanishID } from "../../../validators/validateSpanishID";
import { validateBirthDay } from "../../../validators/validateBirthDay";
import { validateDriversLicenceDate } from "../../../validators/validateDriversLicenceDate";
import { validateZipCode } from "../../../validators/validateZipCode";
import { useContext } from "react";
import { WizardConext } from "../../../contexts/WizardContext";

export const SeguroAutomovilStandAlone = () => {
    const dispatch = useDispatch();
    const { tarifasZurich } = useInsurance();

    const { SeguroAutoActions } = useContext(WizardConext);

    const [isDirty, setIsDirty] = useState(false);
    const [stepSelected, setStepSelected] = useState("DatosClientes");

    useEffect(() => {
        SeguroAutoActions.setCobertura(null);
    }, []);

    useEffect(() => {
        dispatch(
            setSelectedSeguroAutoStandAlone(
                setSeguroStandAlone(
                    SeguroAutoActions.formValuesTomador,
                    SeguroAutoActions.formValuesConductorHabitual,
                    SeguroAutoActions.formValuesConductorOcasional,
                    SeguroAutoActions.Cobertura
                )
            )
        );
    }, [SeguroAutoActions.Cobertura]);

    const ValidateForms = (form) => {
        if (
            !validateSpanishID(form.NIF) ||
            !validateBirthDay(form.FechaNacimiento) ||
            !validateDriversLicenceDate(form.FechaNacimiento, form.FechaCarne) ||
            !validateZipCode(form.CodigoPostal)
        ) {
            return false;
        }
        return true;
    };

    const handleNext = () => {
        setIsDirty(true);

        if (
            ValidateForms(SeguroAutoActions.formValuesTomador) &&
            (SeguroAutoActions.conductorHabitual ||
                (!SeguroAutoActions.conductorHabitual &&
                    ValidateForms(SeguroAutoActions.formValuesConductorHabitual))) &&
            (!SeguroAutoActions.conductorOcasional ||
                (SeguroAutoActions.conductorOcasional && ValidateForms(SeguroAutoActions.formValuesConductorOcasional)))
        ) {
            dispatch(
                setSelectedSeguroAutoStandAlone(
                    setSeguroStandAlone(
                        SeguroAutoActions.formValuesTomador,
                        SeguroAutoActions.formValuesConductorHabitual,
                        SeguroAutoActions.formValuesConductorOcasional,
                        SeguroAutoActions.Cobertura
                    )
                )
            );
            dispatch(GetTarificacionZurichLongDrive());
            setStepSelected("Coberturas");
        } else {
            const notification = new Notification.fromObject({
                Description: "Datos a rojo están incorrectos!",
                Timeout: 3000,
                Type: TYPE_NOTIFICACION.TOAST,
                Severity: SEVERITY_NOTIFICACION.ERROR,
            });
            dispatch(addNotification(notification));
            return;
        }
    };

    const handleBack = () => {
        dispatch(
            setSelectedSeguroAutoStandAlone(
                setSeguroStandAlone(
                    SeguroAutoActions.formValuesTomador,
                    SeguroAutoActions.formValuesConductorHabitual,
                    SeguroAutoActions.formValuesConductorOcasional,
                    SeguroAutoActions.Cobertura
                )
            )
        );
        dispatch(setStepSelected("DatosClientes"));
    };

    return (
        <>
            <Wizard collapseBreakpoint="s" collapsedCounter="1/2" collapsedText="Current Step" noInteraction>
                {stepSelected === "DatosClientes" ? (
                    <>
                        <Wizard.Item key="step_datos_cliente" active>
                            Datos Cliente
                        </Wizard.Item>
                        <Wizard.Item key="step_coberturas">Selección Coberturas</Wizard.Item>
                    </>
                ) : (
                    <>
                        <Wizard.Item key="step_datos_cliente" done>
                            Datos Cliente
                        </Wizard.Item>
                        <Wizard.Item key="step_coberturas" active>
                            Selección Coberturas
                        </Wizard.Item>
                    </>
                )}
            </Wizard>
            <Layout.Item>
                <ButtonContainer>
                    {stepSelected === "Coberturas" && (
                        <Button onClick={() => handleBack()} link small icon="semantic-back">
                            Editar datos cliente
                        </Button>
                    )}
                </ButtonContainer>
            </Layout.Item>
            {stepSelected === "DatosClientes" && (
                <Layout.Item>
                    <Form>
                        <>
                            <Fieldset.Row>
                                <Heading level={6}>Datos tomador/pagador</Heading>
                                <TomadorInfo
                                    formValues={SeguroAutoActions.formValuesTomador}
                                    handleOnChange={SeguroAutoActions.setHandleOnChangeTomador}
                                    isDirty={isDirty}
                                    setValues={SeguroAutoActions.setValuesTomador}
                                ></TomadorInfo>
                                <Fieldset.Row className=" u-mb-none">
                                    <Layout className="u-mt-xsmall">
                                        <Layout.Item default="2/3">
                                            <Heading level={6} className="u-text-left">
                                                ¿Es el tomador/pagador el conductor habitual?
                                            </Heading>
                                        </Layout.Item>
                                        <Layout.Item default="1/3" xs="1/1">
                                            <FormField labelElement="div" staticLabel type="other">
                                                <RadioButtonGroup className="u-float-right">
                                                    <RadioButton
                                                        checked={SeguroAutoActions.conductorHabitual}
                                                        name="ConductorHabitual"
                                                        onChange={() => SeguroAutoActions.setConductorHabitual(true)}
                                                    >
                                                        Sí
                                                    </RadioButton>
                                                    <RadioButton
                                                        checked={!SeguroAutoActions.conductorHabitual}
                                                        name="ConductorHabitual"
                                                        onChange={() => SeguroAutoActions.setConductorHabitual(false)}
                                                    >
                                                        No
                                                    </RadioButton>
                                                </RadioButtonGroup>
                                            </FormField>
                                        </Layout.Item>
                                    </Layout>
                                </Fieldset.Row>
                                {!SeguroAutoActions.conductorHabitual && (
                                    <div className="u-mb-xlarge">
                                        <Heading level={6}>Datos conductor habitual</Heading>
                                        <ClientInfo
                                            formValues={SeguroAutoActions.formValuesConductorHabitual}
                                            handleOnChange={SeguroAutoActions.setHandleOnChangeConductorHabitual}
                                            isDirty={isDirty}
                                            setValues={SeguroAutoActions.setValuesConductorHabitual}
                                        ></ClientInfo>
                                    </div>
                                )}
                                <Fieldset.Row className=" u-mb-none">
                                    <Layout>
                                        <Layout.Item default="2/3">
                                            <Heading level={6} className="u-text-left">
                                                ¿Hay un conductor ocasional?
                                            </Heading>
                                        </Layout.Item>
                                        <Layout.Item default="1/3" xs="1/1">
                                            <FormField labelElement="div" staticLabel type="other">
                                                <RadioButtonGroup className="u-float-right">
                                                    <RadioButton
                                                        checked={SeguroAutoActions.conductorOcasional}
                                                        name="ConductorOcasional"
                                                        onChange={() => SeguroAutoActions.setConductorOcasional(true)}
                                                    >
                                                        Sí
                                                    </RadioButton>
                                                    <RadioButton
                                                        checked={!SeguroAutoActions.conductorOcasional}
                                                        name="ConductorOcasional"
                                                        onChange={() => SeguroAutoActions.setConductorOcasional(false)}
                                                    >
                                                        No
                                                    </RadioButton>
                                                </RadioButtonGroup>
                                            </FormField>
                                        </Layout.Item>
                                    </Layout>
                                </Fieldset.Row>
                                {SeguroAutoActions.conductorOcasional && (
                                    <>
                                        <Heading level={6}>Datos conductor ocasional</Heading>
                                        <ClientInfo
                                            formValues={SeguroAutoActions.formValuesConductorOcasional}
                                            handleOnChange={SeguroAutoActions.setHandleOnChangeConductorOcasional}
                                            isDirty={isDirty}
                                            setValues={SeguroAutoActions.setValuesCondutorOcasional}
                                        ></ClientInfo>
                                    </>
                                )}
                            </Fieldset.Row>
                        </>
                    </Form>
                </Layout.Item>
            )}
            {stepSelected === "Coberturas" && (
                <Coberturas tarifasZurich={tarifasZurich} setCobertura={SeguroAutoActions.setCobertura}></Coberturas>
            )}
            <Layout.Item>
                <div className="u-text-center">
                    {stepSelected === "DatosClientes" && <Button onClick={() => handleNext()}> Siguiente </Button>}
                </div>
            </Layout.Item>
        </>
    );
};
