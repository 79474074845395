export const CalculatorTypeEnum = {
    RENTING: 1,
    FINANCE: 3,
    MANTENIMIENTOSTANDALONE: 4,
    SEGUROAUTOSTANDALONE: 5,
    ALLSTANDALONE: 6,
    LEASING: 7,
};

export const StepsNameEnum = {
    MANTENIMIENTO: "Mantenimiento",
    SEGUROAUTOMOVIL: "Seguro Automovil",
    DATOSCLIENTE: "Datos Cliente",
    RESUMEN: "Resumen",
};
