import { Layout, FormField, Input, Heading, Fieldset, Checkbox } from "@vwfs-bronson/bronson-react";

export const OtherInfoResume = ({ clientData }) => {
    return (
        <>
            <Fieldset.Row>
                <Layout>
                    <Layout.Item default="1/1">
                        <Heading level={6} className="u-text-left u-mt u-mb-none">
                            Otros Datos
                        </Heading>
                    </Layout.Item>
                </Layout>
            </Fieldset.Row>
            <Fieldset.Row>
                <Layout>
                    <Layout.Item default="1/2" xs="1/1">
                        <FormField
                            labelForId="AssociatedPersonToOffer"
                            labelText="Persona asociada a la oferta"
                            type="input"
                        >
                            <Input
                                placeholder="Persona asociada a la oferta"
                                defaultValue={clientData.AssociatedPersonToOffer}
                                readOnly
                            />
                        </FormField>
                    </Layout.Item>
                    <Layout.Item default="1/1" xs="1/1">
                        {clientData.GRPD ? (
                            <Checkbox defaultChecked disabled>
                                <Heading level={6} className="u-m-none">
                                    He informado al cliente sobre sus derechos a efectos del GDPR. Para más información
                                    pulse <a href="#0">aquí</a>
                                </Heading>
                            </Checkbox>
                        ) : (
                            <Checkbox disabled>
                                <Heading level={6} className="u-m-none">
                                    He informado al cliente sobre sus derechos a efectos del GDPR. Para más información
                                    pulse <a href="#0">aquí</a>
                                </Heading>
                            </Checkbox>
                        )}
                    </Layout.Item>
                </Layout>
            </Fieldset.Row>
        </>
    );
};
