import { Layout, FormSection } from "@vwfs-bronson/bronson-react";
import { useSummary } from "../../../../hooks/useSummary";
import { useOffer } from "../../../../hooks/useOffer";
import { LeasingInfo } from "../../../Modals/ModalsOfferDetails/Leasing/LeasingInfo";

export const LeasingOfferResume = () => {
    const { selectedResumeOffers } = useSummary();
    const { isSimulatorOfferSended } = useOffer();
    const offers = selectedResumeOffers.filter((x) => x.OfferType?.Key == "LE");
    if (offers?.length === 0) return null;

    const getSubtitle = () => {
        if (isSimulatorOfferSended === null || !isSimulatorOfferSended?.IsSended) {
            return <></>;
        }
        if (!isSimulatorOfferSended.ItsDone) {
            return <>No se ha podido generar oferta</>;
        }
        var offersId = "";
        isSimulatorOfferSended.OffersId?.forEach((item) => {
            offersId += item + ",";
        });
        return <>{offersId.substring(0, offersId.length - 1)}</>;
    };

    return (
        <Layout.Item className="u-mt-none" default="1/1">
            <FormSection
                defaultOpen={isSimulatorOfferSended === null || !isSimulatorOfferSended?.IsSended ? true : false}
                subtitle={getSubtitle()}
                className="u-mb-small"
                success={isSimulatorOfferSended?.IsSended}
                error={isSimulatorOfferSended !== null && !isSimulatorOfferSended.IsSended}
                title={`Finance - ${offers[0].Name}`}
            >
                {offers.map((offer, index) => (
                    <LeasingInfo key={index} offer={offer}></LeasingInfo>
                ))}
            </FormSection>
        </Layout.Item>
    );
};
